import React from 'react'
import styled from 'styled-components'
import { space, typography } from 'styled-system'
import Box from './Box'

export const MainText = styled.div`
  color: #ffbf42;
  font-family: 'Superior Title';
  font-size: ${({ mainText }) => (mainText === 'ISAAC KANG' ? '64px' : '48px')};
  margin-bottom: ${({ mainText }) => (mainText === 'ISAAC KANG' ? '0' : '30px')};
  ${space}
  ${typography}
`

export const SubText = styled.div`
  font-family: 'Prag';
  font-size: 16px;
  color: ${({ orange }) => (orange ? '#ffbf42' : 'white')};
  ${space}
`

const Title = ({ mainText, subText, orange }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={['flex-start']}
      width={'100%'}
      p={['1rem']}
      mb={24}
    >
      <MainText mainText={mainText}>{mainText}</MainText>
      <SubText orange={orange}>{subText}</SubText>
    </Box>
  )
}

export default Title
