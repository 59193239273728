import React from 'react'
import styled from 'styled-components'
import { typography, color } from 'styled-system'

import ghIcon from '../assets/github.svg'
import twIcon from '../assets/twitter.svg'
import liIcon from '../assets/linkedin.svg'
import emIcon from '../assets/email.svg'
import { MainText, SubText } from '../components/Title'
import { Flex } from '../components/Flex'
import { Icon } from '../components/Icon'
import Box from '../components/Box'
import Seo from '../components/Seo'

const Summary = styled.div`
  font-family: 'Prag';
  font-size: 12px;
  line-height: 1.1rem;
  margin: 2rem 1rem;
  ${typography}
  ${color}
`
const ContactIcons = styled.div`
  display: flex;
  align-self: flex-start;
  margin-left: 1rem;
`

const IndexPage = () => {
  return (
    <>
      <Seo title="System" />
      <Flex column fullWidth p={'1rem'} mb={24}>
        <Box>
          <Flex alignCenter>
            <Icon
              height={72}
              width={72}
              borderRadius="15px"
              display={['none', 'block']}
              src={'/profile.jpg'}
            />
            <MainText fontSize={64} mb={0}>
              ISAAC KANG
            </MainText>
          </Flex>
          <SubText ml={[0, 104]} orange>
            Programmer
          </SubText>
        </Box>
      </Flex>

      <Summary fontSize={[1, 2]} color="grey">
        I build tools and applications for the Ethereum network.
      </Summary>
      <ContactIcons>
        <a href="https://github.com/kangarang" target="_blank" rel="noopener noreferrer">
          <Icon src={ghIcon} />
        </a>
        <a href="https://twitter.com/_kangarang" target="_blank" rel="noopener noreferrer">
          <Icon src={twIcon} />
        </a>
        <a href="https://linkedin.com/in/isaackang" target="_blank" rel="noopener noreferrer">
          <Icon src={liIcon} />
        </a>
        <a href="mailto:isaac.kang@protonmail.ch">
          <Icon src={emIcon} />
        </a>
      </ContactIcons>
    </>
  )
}

export default IndexPage
