import styled from 'styled-components/macro'
import {
  space,
  color,
  layout,
  typography,
  flexbox,
  border,
  background,
  shadow,
  position,
  display,
} from 'styled-system'

export const Flex = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: ${(props) => {
    if (props.$wrap) return 'wrap'
    return 'nowrap'
  }};
  justify-content: ${(props) => {
    if (props.justifyContent) return props.justifyContent
    if (props.justifyCenter) return 'center'
    else if (props.justifyAround) return 'space-around'
    else if (props.justifyBetween) return 'space-between'
    else if (props.justifyEnd) return 'flex-end'
    return 'flex-start'
  }};
  align-items: ${(props) => {
    if (props.alignItems) return props.alignItems
    else if (props.alignEnd) return 'flex-end'
    if (props.alignCenter) return 'center'
    return 'flex-start'
  }};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};

  ${({ bold }) => bold && 'font-weight: bold;'}
  ${({ pointer }) => pointer && 'cursor: pointer;'}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  &:hover {
    ${({ hover }) => {
      if (hover) {
        return `background: #6969691a`
      }
    }};
  }

  transition: all 0.15s ease-out;

  ${display};
  ${space};
  ${color};
  ${layout};
  ${typography};
  ${border};
  ${background};
  ${shadow};
  ${position};
  ${flexbox};
`

export const Circle = styled(Flex)`
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
`
