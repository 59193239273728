import styled from 'styled-components/macro'
import { border, display, layout, space } from 'styled-system'

export const Icon = styled.img`
  height: 35px;
  margin-right: 30px;
  width: 35px;
  ${display}
  ${layout}
  ${space}
  ${border}
`
